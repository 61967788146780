<template>
  <footer class="main-footer">
    <div class="main-footer__links">
      <router-link to="/terms">Terms of Use</router-link>
      <div class="main-footer__spacer">|</div>
      <router-link to="/privacy-policy">Privacy Statement</router-link>
      <div class="main-footer__spacer">|</div>
      <a href="https://www.netflix.com/title/80174608" target="_blank"
        >Netflix</a
      >
    </div>
    <div class="main-footer__feature">
      <span>Powered by</span>
      <a href="https://feature.io/" target="_blank"
        ><img src="@/assets/icons/feature.svg" alt="Feature"
      /></a>
    </div>
  </footer>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .main-footer {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    background: #1e1e1e;
    padding: 0 41px 75px;
    position: relative;
    @media (min-width: 768px) {
      padding: 0 41px 103px;
    }
    &__links {
      display: flex;
      justify-content: center;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 160%;
      text-align: center;
      text-decoration-line: underline;
      a {
        color: #ffffff;
        @media (min-width: 768px) {
          font-family: 'Barlow';
          font-weight: 400s;
        }
      }
    }
    &__spacer {
      margin: 0 10px;
    }
    &__feature {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 4px;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        color: #888888;
      }
      img {
        height: 11px;
      }
    }
  }
</style>
