<template>
  <aside class="drawer-menu" :class="{ display, hide }">
    <main-menu
      class="drawer-menu__header"
      :enableClose="true"
      @close="$emit('close')"
    />
    <div class="drawer-menu__container">
      <ul class="drawer-menu__links">
        <li v-for="(item, i) in menuList" :key="i" @click="$emit('close')">
          <router-link class="drawer-menu__link" :to="item.link">{{
            item.name
          }}</router-link>
        </li>
      </ul>
      <ul class="drawer-menu__buttons">
        <li v-for="(item, i) in buttonList" :key="i">
          <a class="drawer-menu__button" :href="item.link" target="_blank">{{
            item.name
          }}</a>
        </li>
      </ul>
      <div class="drawer-menu__images">
        <a
          href="https://www.netflix.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="@/assets/img/netflix-logo.png"
            alt="netflix-logo"
            class="netflix-logo"
          />
        </a>
      </div>
    </div>
  </aside>
</template>
<script>
  import { ref, toRefs, watch } from 'vue'
  import MainMenu from '@/components/global/MainMenu'
  export default {
    components: { MainMenu },
    props: {
      display: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { display } = toRefs(props)
      const hide = ref(false)
      const menuList = [
        { name: 'HOME', link: '/' },
        { name: 'COLLECTION', link: '/collection' },
        { name: 'COMMUNITY', link: '/#community' },
      ]
      const buttonList = [
        { name: 'SERIES PAGE', link: 'https://www.netflix.com/title/80174608' },
        {
          name: 'TRAILER',
          link: 'https://www.youtube.com/watch?v=Xj2b0swdpX8',
        },
      ]

      watch(display, (newVal) => {
        if (newVal) {
          hide.value = false
        } else {
          hide.value = true
        }
      })

      return { menuList, buttonList, hide }
    },
  }
</script>
<style lang="scss" scoped>
  .drawer-menu {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: #0a0a0a;
    left: -100%;
    @media (min-width: 768px) {
      display: none;
    }
    &__container {
      display: flex;
      flex-direction: column;
      padding: 0 32px 50px;
    }
    &__links {
      list-style: none;
      font-family: 'Knockout';
      font-weight: 400;
      font-size: 2.25rem;
      line-height: 120%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      margin-top: 30px;
      text-align: left;
    }
    &__link {
      color: #ffffff;
      text-decoration: none;
    }
    &__buttons {
      list-style: none;
      font-family: 'Knockout';
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-top: 40px;
      text-align: left;
      a {
        text-decoration: none;
      }
    }
    &__button {
      font-size: 1.125rem;
      color: #8a8a8a;
    }
    &__images {
      margin-top: 40px;
      text-align: left;
    }
    &.display {
      animation: toLeft 0.65s forwards;
    }
    &.hide {
      animation: toRight 0.65s forwards;
    }
    @keyframes toLeft {
      from {
        left: -100%;
      }
      to {
        left: 0;
      }
    }
    @keyframes toRight {
      from {
        left: 0;
      }
      to {
        left: -100%;
      }
    }
  }
  .netflix-logo {
    max-width: 67px;
  }
</style>
