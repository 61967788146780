<template>
  <!-- TODO: Add header. -->
  <div class="main">
    <main-menu
      class="main__menu"
      :class="{ fixed: fixedClass }"
      @open="displayDrawer = true"
    />
    <drawer-menu :display="displayDrawer" @close="displayDrawer = false" />
    <slot />
    <main-footer v-if="!hasNoFooter" />
  </div>
  <!-- TODO: Add footer. -->
</template>

<script>
  import MainMenu from '@/components/global/MainMenu'
  import DrawerMenu from '@/components/global/DrawerMenu'
  import MainFooter from '@/components/global/MainFooter.vue'
  import { computed, ref } from 'vue'
  import { useRoute } from 'vue-router'

  export default {
    name: 'MainLayout',
    components: { DrawerMenu, MainMenu, MainFooter },
    setup() {
      const route = useRoute()
      const displayDrawer = ref(false)
      const hasFixedMenu = [
        '/',
        '/home',
        '/collection',
        '/frequently-asked-questions',
      ]
      const noFooterRoutes = ['Scan Page']
      const fixedClass = computed(() => hasFixedMenu.includes(route.path))
      const hasNoFooter = computed(() => noFooterRoutes.includes(route.name))

      return { displayDrawer, fixedClass, hasNoFooter, route }
    },
  }
</script>

<style lang="scss" scoped>
  .main {
    width: 100%;
    height: 100%;
    background: #1e1e1e;
    &__menu {
      position: relative;
      top: 0;
      width: 100%;
      z-index: 100;
      // background: rgba(0,0,0,0.3);
      &.fixed {
        position: fixed;
      }
    }
  }
</style>
