export const COLLECTION_ROUTE = '/collection'
export const FAQ = '/frequently-asked-questions'
export const HOME_ROUTE = '/home'
export const INDEX_ROUTE = '/'
export const SCAN_ROUTE = '/:token'
export const THE_HUNT = '/the-hunt'
export const TERMS = '/terms'
export const PRIVACY_POLICY = '/privacy-policy'
export const HOMER_POE_1 = '/HomerPoeMelville'
export const HOMER_POE_2 = '/homerpoemelville'
