<template>
  <header class="main-menu">
    <div class="main-menu__left" @click="$router.push('/')">
      <img class="main-menu__img" src="@/assets/icons/like.svg" alt="like" />
      <img class="main-menu__img" src="@/assets/icons/close.svg" alt="close" />
      <img class="main-menu__img" src="@/assets/icons/group.svg" alt="group" />
    </div>
    <ul class="main-menu__desktop hidden-sm">
      <li v-for="(item, i) in menuDesktop" :key="i">
        <component
          :is="item.externalLink ? 'a' : 'router-link'"
          class="main-menu__link"
          :class="{ active: $route.path === item.link }"
          v-bind="
            item.externalLink
              ? { href: item.link, target: '_blank' }
              : { to: item.link }
          "
          >{{ item.name }}</component
        >
      </li>
    </ul>
    <div class="main-menu__right hidden-lg">
      <img
        v-if="enableClose"
        class="main-menu__img"
        src="@/assets/icons/close-modal.svg"
        alt="menu"
        @click="$emit('close')"
      />
      <img
        v-else
        class="main-menu__img"
        src="@/assets/icons/menu.svg"
        alt="menu"
        @click="$emit('open')"
      />
    </div>
    <div class="main-menu__right hidden-sm">
      <a
        href="https://www.netflix.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/assets/img/netflix-logo.png"
          alt="netflix-logo"
          class="netflix-logo"
        />
      </a>
    </div>
  </header>
</template>

<script>
  export default {
    props: ['enableClose'],
    setup() {
      const menuDesktop = [
        { name: 'HOME', link: '/' },
        { name: 'COLLECTION', link: '/collection' },
        { name: 'COMMUNITY', link: '/#community' },
        {
          name: 'SERIES PAGE',
          link: 'https://www.netflix.com/title/80174608',
          externalLink: true,
        },
        {
          name: 'TRAILER',
          link: 'https://www.youtube.com/watch?v=Xj2b0swdpX8',
          externalLink: true,
        },
      ]
      return { menuDesktop }
    },
  }
</script>

<style lang="scss" scoped>
  .main-menu {
    display: flex;
    justify-content: space-between;
    padding: 30px 35px 25px;
    &__img {
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 3px;
      }
    }
    &__desktop {
      list-style: none;
      font-family: 'Knockout';
      display: flex;
      align-items: center;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 1.125rem;
      text-align: left;
      letter-spacing: 3px;
      justify-content: space-between;
      width: 38%;
      min-width: 546px;
      max-width: 800px;
    }
    &__link {
      color: #ffffff;
      transition: all 0.35s ease;
      text-decoration: none;
      &:hover,
      &.active {
        color: #6c6c6c;
      }
    }
  }
  .netflix-logo {
    max-width: 67px;
  }
</style>
