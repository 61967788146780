<template>
  <div v-if="!loading">
    <Layout>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </Layout>
  </div>
</template>

<script>
  // import { computed, ref, onMounted } from 'vue'
  // import { useStore } from 'vuex'

  import Layout from '@/layouts/Layout'

  // import web3Modal from '@/web3Modal'
  // import { connectProvider } from '@/commons/provider'

  export default {
    name: 'App',

    components: {
      Layout,
    },

    setup() {
      // const store = useStore()
      // const {
      //   state: { wallet },
      // } = store
      // const loading = ref(false)
      // const walletAddress = computed(() => {
      //   return wallet.address
      // })
      // const doConnect = async (cachedProvider = 'walletlink') => {
      //   loading.value = true
      //   const walletConnected = !!wallet.address
      //   if (!walletConnected) {
      //     await connectProvider(cachedProvider)
      //   }
      //   loading.value = false
      // }
      // onMounted(async () => {
      //   const { cachedProvider } = web3Modal
      //   if (cachedProvider) {
      //     await doConnect(cachedProvider)
      //   }
      // })
      // return {
      //   loading,
      //   walletAddress,
      // }
    },
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
