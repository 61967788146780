import { createRouter, createWebHistory } from 'vue-router'

import {
  HOME_ROUTE,
  INDEX_ROUTE,
  COLLECTION_ROUTE,
  TERMS,
  PRIVACY_POLICY,
} from '../constants/routes'

const routes = [
  {
    path: INDEX_ROUTE,
    component: () => import('@/views/Home'),
  },
  {
    path: HOME_ROUTE,
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: COLLECTION_ROUTE,
    component: () => import('@/views/Collection'),
  },

  {
    path: TERMS,
    name: 'Terms',
    component: () => import('@/views/Terms'),
  },
  {
    path: PRIVACY_POLICY,
    name: 'Privacy Policy',
    component: () => import('@/views/PrivacyPolicy'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
